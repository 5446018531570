exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accounting-js": () => import("./../../../src/pages/accounting.js" /* webpackChunkName: "component---src-pages-accounting-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-china-corporate-services-company-liquidation-js": () => import("./../../../src/pages/china/corporate-services/company-liquidation.js" /* webpackChunkName: "component---src-pages-china-corporate-services-company-liquidation-js" */),
  "component---src-pages-china-corporate-services-company-registration-js": () => import("./../../../src/pages/china/corporate-services/company-registration.js" /* webpackChunkName: "component---src-pages-china-corporate-services-company-registration-js" */),
  "component---src-pages-china-corporate-services-company-restructure-js": () => import("./../../../src/pages/china/corporate-services/company-restructure.js" /* webpackChunkName: "component---src-pages-china-corporate-services-company-restructure-js" */),
  "component---src-pages-china-corporate-services-profit-repatriation-js": () => import("./../../../src/pages/china/corporate-services/profit-repatriation.js" /* webpackChunkName: "component---src-pages-china-corporate-services-profit-repatriation-js" */),
  "component---src-pages-editorial-policy-js": () => import("./../../../src/pages/editorial-policy.js" /* webpackChunkName: "component---src-pages-editorial-policy-js" */),
  "component---src-pages-founders-diary-js": () => import("./../../../src/pages/founders-diary.js" /* webpackChunkName: "component---src-pages-founders-diary-js" */),
  "component---src-pages-getstarted-js": () => import("./../../../src/pages/getstarted.js" /* webpackChunkName: "component---src-pages-getstarted-js" */),
  "component---src-pages-hong-kong-company-registration-js": () => import("./../../../src/pages/hong-kong/company-registration.js" /* webpackChunkName: "component---src-pages-hong-kong-company-registration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-hong-kong-business-bank-account-opening-js": () => import("./../../../src/pages/online-hong-kong-business-bank-account-opening.js" /* webpackChunkName: "component---src-pages-online-hong-kong-business-bank-account-opening-js" */),
  "component---src-pages-payment-guidelines-js": () => import("./../../../src/pages/payment-guidelines.js" /* webpackChunkName: "component---src-pages-payment-guidelines-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-switch-js": () => import("./../../../src/pages/switch.js" /* webpackChunkName: "component---src-pages-switch-js" */),
  "component---src-pages-switch-to-air-corporate-js": () => import("./../../../src/pages/switch-to-air-corporate.js" /* webpackChunkName: "component---src-pages-switch-to-air-corporate-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-video-guides-js": () => import("./../../../src/pages/video-guides.js" /* webpackChunkName: "component---src-pages-video-guides-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-video-guide-js": () => import("./../../../src/templates/video-guide.js" /* webpackChunkName: "component---src-templates-video-guide-js" */)
}

