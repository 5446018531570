import * as React from 'react';
import './src/styles/global.css';
import './src/styles/typography.scss';
import './src/styles/global.scss';
import WhatsAppIcon from './src/images/icons/whats-app-chat.svg';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from './config/prismic/previews';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
    <a target="_blank" rel="noreferrer" href="https://wa.me/+85298138802">
      <img src={WhatsAppIcon} alt="WhatsAppIcon" className="whats-app-chat-icon" />
    </a>
  </PrismicPreviewProvider>
);
