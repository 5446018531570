import React from 'react';
import { linkResolver } from './link-resolver';

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      homePage: React.lazy(() => import('../../src/pages')),
      post: React.lazy(() => import('../../src/templates/blog-post'))
    }
  }
];
